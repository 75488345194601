import styled from "styled-components";
import { isPureTouchDevice } from "../utils/browser";
import { notReachable } from "../errors/UnreachableCaseError";
import type { AvatarSize } from "./Avatar";

export const AvatarContainer = styled.div<{ size: AvatarSize; disabled?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  line-height: 0;
  flex-shrink: 0;
  aspect-ratio: 1 / 1;
  ${props => (props.disabled ? "opacity: 0.5;" : "")};
  ${props => avatarSizeToCSS(props.size)}
`;

export function avatarSizeToCSS(size: AvatarSize): string {
  switch (size) {
    case "micro":
      return `
        width: ${isPureTouchDevice ? 17 : 14}px;
        height: ${isPureTouchDevice ? 17 : 14}px;
        font-size: 8px;
      `;
    case "tiny":
      return `
        width: ${isPureTouchDevice ? 18 : 16}px;
        height: ${isPureTouchDevice ? 18 : 16}px;
        font-size: 9px;
      `;
    case "small":
      return `
        width: 18px;
        height: 18px;
        font-size: 9px;
      `;
    case "regular":
      return `
        width: 24px;
        height: 24px;
        font-size: 11px;
      `;
    case "large":
      return `
        width: 32px;
        height: 32px;
        font-size: 14px;
      `;
    case "huge":
      return `
        width: 64px;
        height: 64px;
        font-size: 22px;
      `;

    default:
      throw notReachable(size);
  }
}
