"use client";

import React from "react";
import styled, { css } from "styled-components";
import { notReachable } from "../errors/UnreachableCaseError";
import { ColorConverter } from "../utils/ColorConverter";
import { AvatarHelper } from "./AvatarHelper";
import { AvatarContainer } from "./AvatarContainer";

/** Size of the avatar. */
export type AvatarSize = "huge" | "large" | "regular" | "small" | "tiny" | "micro";

/** Props for the Avatar component */
export type AvatarProps = {
  /** Location of the avatar. */
  src?: string;
  /** Name of the avatar holder. */
  name?: string;
  /** Persisted ID of the avatar holder. */
  modelId?: string;
  /** Avatar size. */
  size?: AvatarSize;
  /** Should the avatar render as inactive. */
  isInactive?: boolean;
  /** Should the avatar render as for an invite. */
  isInvite?: boolean;
  /** Render the presence indicator as a child of the root container */
  presenceIndicator?: React.ReactNode;
  /** CSS classes */
  className?: string;
  /** CSS styles */
  style?: React.CSSProperties;
  /** Whether the avatar should be rendered as disabled. Defaults to false. */
  disabled?: boolean;
  /** Aria label for the avatar */
  "aria-label"?: string;
  /** Whether element should be hidden from screen readers */
  "aria-hidden"?: boolean;
};

/**
 * Component to display an avatar for a user or entity.
 */
export function Avatar(props: AvatarProps) {
  const {
    src,
    name,
    modelId,
    size = "regular",
    isInactive,
    isInvite,
    presenceIndicator,
    className,
    style,
    disabled,
    ...etc
  } = props;

  return (
    <AvatarContainer size={size} style={style} className={className}>
      <AvatarPicture
        src={src}
        isInactive={isInactive}
        name={name}
        modelId={modelId}
        isInvite={isInvite}
        size={size}
        disabled={disabled}
        {...etc}
      />
      {presenceIndicator}
    </AvatarContainer>
  );
}

type AvatarPictureProps = Pick<
  AvatarProps,
  "src" | "isInactive" | "isInvite" | "modelId" | "name" | "disabled" | "aria-label" | "aria-hidden"
> & {
  /** Size of the avatar. */
  size: AvatarSize;
};

/**
 * Component to display an avatar picture for a user or entity.
 */
export function AvatarPicture(props: AvatarPictureProps) {
  const [error, setError] = React.useState(false);
  const size = avatarSizeToPixelSize(props.size);

  return props.src && !error ? (
    <AvatarImg
      src={props.src}
      width={size}
      height={size}
      alt={`Avatar of ${props.name}`}
      aria-label={props["aria-label"]}
      aria-hidden={props["aria-hidden"]}
      $isInactive={props.isInactive}
      $disabled={props.disabled}
      onError={() => {
        setError(true);
      }}
    />
  ) : (
    <AvatarInitials
      name={props.name}
      modelId={props.modelId}
      isInactive={props.isInactive}
      isInvite={props.isInvite}
      disabled={props.disabled}
      aria-label={props["aria-label"]}
      aria-hidden={props["aria-hidden"]}
    />
  );
}

function AvatarInitials(props: {
  name?: string;
  modelId?: string;
  isInactive?: boolean;
  isInvite?: boolean;
  disabled?: boolean;
  "aria-label"?: string;
  "aria-hidden"?: boolean;
}) {
  const { name, isInvite } = props;

  const memoInitials = React.useMemo(() => (name ? AvatarHelper.initials(name) : null), [name]);
  const bgColor = React.useMemo(() => AvatarHelper.initialsBackgroundColor(props.modelId || "rdm"), [props.modelId]);

  return (
    <StyledAvatarInitials
      $isDisabled={props.disabled}
      $isInactive={props.isInactive}
      $color={bgColor}
      $isInvite={isInvite}
      aria-label={props["aria-label"]}
      aria-hidden={props["aria-hidden"]}
    >
      {memoInitials}
    </StyledAvatarInitials>
  );
}

const AvatarImg = styled.img<{ $isInactive?: boolean; $disabled?: boolean }>`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;

  ${props =>
    props.$disabled
      ? css`
          filter: opacity(0.65);
        `
      : ""}
  ${props =>
    props.$isInactive
      ? css`
          box-shadow: 0 0 0 1px ${props.theme.color.bgBorderSolid};
          background-color: ${props.theme.color.bgBorder};
          filter: opacity(0.5) grayscale(1);
        `
      : ""};
`;

interface InitialProps {
  $color: string;
  $isInactive?: boolean;
  $isInvite?: boolean;
  $isDisabled?: boolean;
}

const StyledAvatarInitials = styled.div<InitialProps>`
  display: flex;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: ${props => props.$color};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: opacity ease-in-out 0.2s;

  ${props =>
    props.$isDisabled &&
    css`
      background-color: ${ColorConverter.mixCss(
        props.theme.color.bgBase,
        props.$color,
        props.theme.isDark ? 0.3 : 0.6
      )};
      color: ${props.theme.isDark ? "#ffffff55" : "#FFFFFF"};
    `}
  ${props =>
    props.$isInactive
      ? css`
          color: ${props.theme.color.labelFaint};
          background-color: ${props.theme.color.bgBorderSolid};
        `
      : ""};
  ${props =>
    props.$isInvite
      ? css`
          color: ${props.theme.color.labelFaint};
          border: 1px dashed ${props.theme.color.bgBorderSolid};
          background-color: ${props.theme.color.bgBase};
        `
      : ""};
`;

export function avatarSizeToPixelSize(size: AvatarSize): number {
  switch (size) {
    case "micro":
      return 14;
    case "tiny":
      return 16;
    case "small":
      return 18;
    case "regular":
      return 24;
    case "large":
      return 32;
    case "huge":
      return 64;

    default:
      throw notReachable(size);
  }
}
