"use client";

import * as React from "react";
import clsx from "clsx";
import { useTheme } from "styled-components";
import type { Colors } from "../styles/Theme";

/** Props accepted by <Icon /> */
export type IconProps = Omit<React.SVGProps<SVGSVGElement>, "color" | "fill" | "size"> & {
  /** Size of the icon. Defaults to 16x16 */
  size?: number | string;
  /** Viewbox — only used occasionally, most icons should be 16x16 base */
  viewBox?: string;
  /** If a fill should be not be applied to the base svg */
  noFill?: boolean;
  /**
   * Color of the icon. Defaults to `labelMuted`.
   */
  color?: StringWithAutocomplete<Colors | "currentColor" | "brand" | CSSVariable>;
  /** Additional style applied to the <svg> element. */
  style?: React.CSSProperties;
  /** Additional className applied to the <svg> element. */
  className?: string;
  /** Announced label for the icon. Most icons should not be announced. */
  "aria-label"?: string;
};

/**
 * Wraps an SVG from our icon library with default props and sizing.
 */
export const Icon = (
  props: IconProps & {
    // Children must be a single <svg /> child
    children: React.ReactElement<React.SVGAttributes<SVGSVGElement>>;
  }
) => {
  const theme = useTheme();
  const {
    size = 16,
    width,
    height,
    viewBox = "0 0 16 16",
    color = "labelMuted",
    style,
    noFill = false,
    className,
    children,
    ...etc
  } = props;
  const child = React.Children.only(children);

  const resolvedColor = color in theme.color ? theme.color[color as Colors] : color === "brand" ? undefined : color;

  const svgProps: React.SVGProps<SVGSVGElement> = {
    ...etc,
    className: clsx(className, {
      // Brand icons should avoid color overrides in menus, lists, etc.
      "color-override": color === "brand",
    }),
    style: {
      ...style,
      ["--icon-color" as string]: resolvedColor,
    },
    width: width ?? size,
    height: height ?? size,
    viewBox,
    fill: noFill ? "none" : resolvedColor,
    role: "img",
    focusable: "false",
    // Icons are decorative by default, but can be made accessible by setting aria-label
    "aria-hidden": "aria-hidden" in etc ? etc["aria-hidden"] : "aria-label" in etc ? undefined : true,
    // xmlns is no longer needed for SVG embedded in HTML
    xmlns: undefined,
  };

  if (React.isValidElement(child)) {
    return React.cloneElement(child, svgProps);
  }

  return null;
};
