import isFunction from "lodash/isFunction";

/** Helper for sorting functionality. */
export namespace SortHelper {
  /**
   * Naturally sorts an array of items alphabetically the given predicate. Case and punctuation insensitive.
   *
   * @param predicate The property name or a function that returns the value to sort by.
   * @returns A new, sorted array.
   */
  export const natural = <T>(items: T[], predicate: keyof T | ((item: T) => string)) => {
    return items.slice().sort((a: T, b: T) => {
      const aValue = isFunction(predicate) ? predicate(a) : a[predicate];
      const bValue = isFunction(predicate) ? predicate(b) : b[predicate];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue, undefined, { ignorePunctuation: true });
      }

      throw new Error("Cannot sort by property " + String(predicate) + " of type " + typeof aValue);
    });
  };
}
