import { useMedia } from "./react-use";

/** The supported screen sizes. */
export enum ScreenSize {
  phone = "phone",
  tablet = "tablet",
  laptop = "laptop",
  normal = "normal",
  large = "large",
  xlarge = "xlarge",
}

/** The breakpoints for each screen size. */
export const screenSizeBreakPoints = {
  phone: 640,
  tablet: 768,
  laptop: 1024,
  large: 1400,
  xlarge: 1800,
};

/** At which size the sidebar will always be collapsed on smaller screen. */
export const sidebarAlwaysCollapsedScreenSize: ScreenSize = ScreenSize.laptop;

/**
 * Hook to check if the user's screen is of a certain size.
 *
 * @returns Whether the current screen matches the given screen size. Screen size "tablet" includes very small
 * screens that are matched by the screen size "phone".
 */
export const useScreenSize = (screenSize: ScreenSize): boolean => {
  let query: string;
  switch (screenSize) {
    case ScreenSize.phone:
      query = `(max-width: ${screenSizeBreakPoints.phone}px)`;
      break;
    case ScreenSize.tablet:
      query = `(max-width: ${screenSizeBreakPoints.tablet}px)`;
      break;
    case ScreenSize.laptop:
      query = `(max-width: ${screenSizeBreakPoints.laptop}px)`;
      break;
    case ScreenSize.large:
      query = `(min-width: ${screenSizeBreakPoints.large}px)`;
      break;
    case ScreenSize.xlarge:
      query = `(min-width: ${screenSizeBreakPoints.xlarge}px)`;
      break;
    default:
      query = `(min-width: ${screenSizeBreakPoints.laptop + 1}px)`;
      break;
  }

  return useMedia(query);
};

/** Hook to get the current screen size. */
export const useGetScreenSize = (): ScreenSize => {
  const isPhone = useScreenSize(ScreenSize.phone);
  const isTablet = useScreenSize(ScreenSize.tablet);
  const isLaptop = useScreenSize(ScreenSize.laptop);
  const isLarge = useScreenSize(ScreenSize.large);
  const isXlarge = useScreenSize(ScreenSize.xlarge);

  if (isPhone) {
    return ScreenSize.phone;
  }

  if (isTablet) {
    return ScreenSize.tablet;
  }

  if (isLaptop) {
    return ScreenSize.laptop;
  }

  if (isXlarge) {
    return ScreenSize.xlarge;
  }

  if (isLarge) {
    return ScreenSize.large;
  }

  return ScreenSize.normal;
};
